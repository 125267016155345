import React, { useState, useEffect, useCallback } from "react";
import Loader from "react-js-loader";
import Filter from "./filter";
import {
  Grid,
  Typography,
  IconButton,
  Box,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  SvgIcon,
} from "@material-ui/core";
import BusinessCenterOutlinedIcon from "@material-ui/icons/BusinessCenterOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import MyLocationOutlinedIcon from "@material-ui/icons/MyLocationOutlined";
import PowerOutlinedIcon from "@material-ui/icons/PowerOutlined";
import ArrowForwardIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import CategoryOutlinedIcon from "@material-ui/icons/CategoryOutlined";
import MediaCard from "./card";
import Constants from "./constants";
import FilterType from "./filter-type";

const contentful = require("contentful");

function GridPost({ type }) {
  const [params, setParams] = useState(
    type === "google.com"
      ? {
          content_type: "1xYw5JsIecuGE68mmGMg20",
          limit: 10,
          skip: 0,
        }
      : {
          content_type: "1xYw5JsIecuGE68mmGMg20",
          limit: 10,
          skip: 0,
          "fields.privacy[in]": "public",
        }
  );
  const [data, setData] = useState(null);
  const [isLoanding, setIsLoanding] = useState(false);
  const [isFirstPage, setIsFirstPage] = useState(true);
  const [isLastPage, setIsLastPage] = useState(false);
  const [firstElement, setFirstElement] = useState(1);
  const [lastElement, setLastElement] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [checkDemo, setCheckDemo] = useState(false);
  const [checkBuilder, setCheckBuilder] = useState(false);
  const [checkCarto3, setCheckCarto3] = useState(false);
  const [checkFrames, setCheckFrames] = useState(false);
  const [checkCustom, setCheckCustom] = useState(false);
  const [checkFeatured, setCheckFeatured] = useState(false);

  const Corporate = (props) => {
    return (
      <SvgIcon {...props}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          enableBackground="new 0 0 24 24"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="#000000"
        >
          <rect fill="none" height="24" width="24" />
          <path d="M12,7V3H2v18h20V7H12z M10,19H4v-2h6V19z M10,15H4v-2h6V15z M10,11H4V9h6V11z M10,7H4V5h6V7z M20,19h-8V9h8V19z M18,11h-4v2 h4V11z M18,15h-4v2h4V15z" />
        </svg>
      </SvgIcon>
    );
  };
  // PAGINATION. Function to get the next elements
  const nextPage = (e) => {
    e.preventDefault();
    let newParams = { ...params };
    newParams.skip = newParams.skip + 10;
    setFirstElement(newParams.skip + 1);
    setIsFirstPage(false);
    checkLastPage(data.total);
    return setParams(newParams);
  };

  // PAGINATION. Function to get the previous elements
  const prevPage = (e) => {
    e.preventDefault();
    let newParams = { ...params };
    newParams.skip = newParams.skip - 10;
    if (newParams.skip === 0) {
      setIsFirstPage(true);
    }
    setFirstElement(newParams.skip + 1);
    checkLastPage(data.total);
    return setParams(newParams);
  };

  const checkLastPage = useCallback(
    (total) => {
      if (total <= firstElement + 9) {
        setIsLastPage(true);
        setLastElement(total);
        return;
      }
      setIsLastPage(false);
      setLastElement(params.skip + 10);
    },
    [firstElement, params.skip]
  );

  const reStartQuery = () => {
    setFirstElement(1);
    setIsFirstPage(true);
    setIsLastPage(false);
    params.skip = 0;
  };

  /*     const [countries, setCountries] = useState(query)

  const uniqueByKey = (array, key1, key2) => {
    return [...new Map(array.map((x) => [x[key1][key2], x])).values()];
  }

  const countriesArray = () => {
    const newArray = uniqueByKey(countries.allContentfulDemo.edges, 'node', 'country');
    var FILTER_COUNTRY = newArray.map(function (country) {
        return {title: country.node.country, value: country.node.country}; 
    });
    console.log('Filter', JSON.stringify(FILTER_COUNTRY))
    return FILTER_COUNTRY
  } */

  useEffect(() => {
    setIsLoanding(true);
    const client = contentful.createClient({
      space: "zue4uljq21fx",
      environment: "master", // defaults to 'master' if not set
      accessToken:
        "2bf7cb0d3ba5c3e4d25899ba280186faa825f839a56cb1d497c9aba006355d8c",
    });
    client
      .getEntries(params)
      .then((response) => {
        setData(response);
        checkLastPage(response.total);
        setIsLoanding(false);
      })
      .catch(console.error);
  }, [checkLastPage, params]);

  const handleCheckFilters = (e, field) => {
    reStartQuery();
    let newParams = { ...params };
    // If they checked the input
    if (e.target.checked) {
      // If the property already exists, add the param to thee string
      if (newParams[field]) {
        if (newParams[field].includes(e.target.value)) {
          return;
        }
        newParams[field] = `${newParams[field]}${e.target.value},`;
        return setParams(newParams);
      }
      newParams[field] = `${e.target.value},`;
      return setParams(newParams);
    }

    // If they unchecked, and first validation
    if (newParams[field].includes(e.target.value)) {
      const newSliceParam = newParams[field].replace(`${e.target.value},`, "");
      // If the array is empty, delete the property of the object
      if (!newSliceParam) {
        delete newParams[field];
        return setParams(newParams);
      }
      newParams[field] = newSliceParam;
      return setParams(newParams);
    }
  };

  const handleChangeSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const searchQuery = (value) => {
    reStartQuery();
    let newParams = { ...params };
    newParams["query"] = value;
    return setParams(newParams);
  };

  const handleKeyPressSearch = (e) => {
    if (e.key === "Enter") {
      searchQuery(e.target.value);
    }
  };

  const cancelSearch = () => {
    if (params.query || searchValue) {
      setSearchValue("");
      searchQuery("");
    }
  };

  const handleChangeSortBy = (e) => {
    e.preventDefault();
    reStartQuery();
    let newParams = { ...params };
    newParams["order"] = e.target.value;
    return setParams(newParams);
  };

  const handleCheckFiltersType = (e, field) => {
    reStartQuery();
    let newParams = { ...params };
    // If they checked the input
    if (e.target.checked) {
      /* ----- For the Demo checkbox ----- */
      if (
        e.target.value ===
        "Demo-Builder,Demo-Verticalized Apps,Demo-Workflows,Demo-Custom App,"
      ) {
        setCheckDemo(true);
        setCheckBuilder(true);
        setCheckCarto3(true);
        setCheckFrames(true);
        setCheckCustom(true);
        setCheckFeatured(true);
        if (!newParams[field]) {
          newParams[field] = `${e.target.value}`;
          return setParams(newParams);
        }
        if (newParams[field].includes("Demo-Builder,")) {
          newParams[field] = newParams[field].replace("Demo-Builder,", "");
        }
        if (newParams[field].includes("Demo-Verticalized Apps,")) {
          newParams[field] = newParams[field].replace(
            "Demo-Verticalized Apps,",
            ""
          );
        }
        if (newParams[field].includes("Demo-Workflows,")) {
          newParams[field] = newParams[field].replace("Demo-Workflows,", "");
        }
        if (newParams[field].includes("Demo-Custom App,")) {
          newParams[field] = newParams[field].replace("Demo-Custom App,", "");
        }
        if (newParams[field].includes("Demo-featured,")) {
          newParams[field] = newParams[field].replace("Demo-featured,", "");
        }
        newParams[field] = `${newParams[field]}${e.target.value}`;
        return setParams(newParams);
      }
      /* ----- END. For the Demo checkbox ----- */
      if (e.target.value === "Demo-Builder,") {
        setCheckBuilder(true);
        if (checkCarto3 && checkFrames && checkCustom && checkFeatured) {
          setCheckDemo(true);
        }
      }
      if (e.target.value === "Demo-Verticalized Apps,") {
        setCheckCarto3(true);
        if (checkBuilder && checkFrames && checkCustom && checkFeatured) {
          setCheckDemo(true);
        }
      }
      if (e.target.value === "Demo-Workflows,") {
        setCheckFrames(true);
        if (checkBuilder && checkCarto3 && checkCustom && checkFeatured) {
          setCheckDemo(true);
        }
      }
      if (e.target.value === "Demo-Custom App,") {
        setCheckCustom(true);
        if (checkBuilder && checkCarto3 && checkFrames && checkFeatured) {
          setCheckDemo(true);
        }
      }
      if (e.target.value === "Demo-featured,") {
        setCheckFeatured(true);
        if (checkBuilder && checkCarto3 && checkFrames && checkCustom) {
          setCheckDemo(true);
        }
      }

      // If the property already exists, add the param to the string
      if (newParams[field]) {
        if (newParams[field].includes(e.target.value)) {
          return;
        }
        newParams[field] = `${newParams[field]}${e.target.value}`;
        return setParams(newParams);
      }
      newParams[field] = `${e.target.value}`;
      return setParams(newParams);
    }

    // If they unchecked, and first validation

    /* ----- Unchecked For the Demo checkbox ----- */
    if (
      e.target.value ===
      "Demo-Builder,Demo-Verticalized Apps,Demo-Workflows,Demo-Custom App,"
    ) {
      setCheckDemo(false);
      setCheckBuilder(false);
      setCheckCarto3(false);
      setCheckFrames(false);
      setCheckCustom(false);
      setCheckFeatured(false);
      if (newParams[field].includes("Demo-Builder,")) {
        newParams[field] = newParams[field].replace("Demo-Builder,", "");
      }
      if (newParams[field].includes("Demo-Verticalized Apps,")) {
        newParams[field] = newParams[field].replace(
          "Demo-Verticalized Apps,",
          ""
        );
      }
      if (newParams[field].includes("Demo-Workflows,")) {
        newParams[field] = newParams[field].replace("Demo-Workflows,", "");
      }
      if (newParams[field].includes("Demo-Custom App,")) {
        newParams[field] = newParams[field].replace("Demo-Custom App,", "");
      }
      // If the array is empty, delete the property of the object
      if (!newParams[field]) {
        delete newParams[field];
        return setParams(newParams);
      }
      return setParams(newParams);
    }
    /* ----- END. Unchecked For the Demo checkbox ----- */
    if (e.target.value === "Demo-Builder,") {
      setCheckDemo(false);
      setCheckBuilder(false);
    }
    if (e.target.value === "Demo-Verticalized Apps,") {
      setCheckDemo(false);
      setCheckCarto3(false);
    }
    if (e.target.value === "Demo-Workflows,") {
      setCheckDemo(false);
      setCheckFrames(false);
    }
    if (e.target.value === "Demo-Custom App,") {
      setCheckDemo(false);
      setCheckCustom(false);
    }
    if (e.target.value === "Demo-featured,") {
      setCheckDemo(false);
      setCheckFeatured(false);
    }
    if (newParams[field].includes(e.target.value)) {
      const newSliceParam = newParams[field].replace(`${e.target.value}`, "");
      // If the array is empty, delete the property of the object
      if (!newSliceParam) {
        delete newParams[field];
        return setParams(newParams);
      }
      newParams[field] = newSliceParam;
      return setParams(newParams);
    }
  };

  return (
    <>
      {isLoanding && (
        <div className="loading">
          <Loader
            type="bubble-scale"
            bgColor={"#162945"}
            title={"Loading..."}
            color={"#162945"}
            size={40}
          />
        </div>
      )}

      {data && (
        <Grid container spacing={6} direction="row" justifyContent="center">
          <Grid item sm={3} xs={10}>
            <Typography variant="subtitle1" className="subtitleFilters">
              Filters
            </Typography>
            <div id="filters">
              <FilterType
                title="Type"
                icon={<CategoryOutlinedIcon />}
                subItem={Constants.FILTER_TYPE2}
                action={(e) =>
                  handleCheckFiltersType(e, "fields.typeOfContent[in]")
                }
                checkDemo={checkDemo}
                checkBuilder={checkBuilder}
                checkCarto3={checkCarto3}
                checkFrames={checkFrames}
                checkCustom={checkCustom}
                checkFeatured={checkFeatured}
              />
              <Filter
                title="Industry"
                icon={<Corporate />}
                subItem={Constants.FILTER_INDUSTRY}
                action={(e) =>
                  handleCheckFilters(e, "fields.verticalId.sys.id[in]")
                }
              />
              <Filter
                title="Use Case"
                icon={<BusinessCenterOutlinedIcon />}
                subItem={Constants.FILTER_USECASE}
                action={(e) =>
                  handleCheckFilters(e, "fields.useCase.sys.id[in]")
                }
              />
              {type === "google.com" && (
                <Filter
                  title="Privacy"
                  icon={<VisibilityOutlinedIcon />}
                  subItem={Constants.FILTER_PRIVACY}
                  action={(e) => handleCheckFilters(e, "fields.privacy[in]")}
                />
              )}
              <Filter
                title="Location"
                icon={<MyLocationOutlinedIcon />}
                subItem={Constants.FILTER_LOCATION}
                action={(e) => handleCheckFilters(e, "fields.country[in]")}
              />
              <Filter
                title="Cloud Partner"
                icon={<PowerOutlinedIcon />}
                subItem={Constants.FILTER_CLOUD}
                action={(e) =>
                  handleCheckFilters(e, "fields.cloudPartner.sys.id[in]")
                }
              />
            </div>
          </Grid>

          <Grid item sm={7} xs={12}>
            <Grid
              container
              spacing={3}
              justifyContent="space-between"
              className="topFilters"
            >
              <Grid item sm={6}>
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <SearchIcon color="disabled" />
                  <TextField
                    id="search-keywords"
                    label="Search by keywords"
                    variant="standard"
                    value={searchValue}
                    onKeyPress={(e) => handleKeyPressSearch(e)}
                    onChange={(e) => handleChangeSearch(e)}
                  />
                  <CloseIcon
                    color="disabled"
                    className="cancelSearch"
                    onClick={() => cancelSearch()}
                  />
                </Box>
              </Grid>
              <Grid item sm={3}>
                <FormControl>
                  <InputLabel id="sort-by-select-label">Sort By:</InputLabel>
                  <Select
                    variant="standard"
                    color="primary"
                    labelId="sort-by-select-label"
                    id="sort-by-select"
                    defaultValue=""
                    onChange={(e) => handleChangeSortBy(e)}
                  >
                    <MenuItem value="-sys.createdAt">Newest</MenuItem>
                    <MenuItem value="sys.createdAt">Oldest</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              {data.items.map((card, i) => (
                <Grid item sm={6} xs={12} key={`grid-${i}`}>
                  <MediaCard
                    key={`card-${i}`}
                    userType={type}
                    title={card.fields.title}
                    imgURL={card.fields.screenshot.fields.file.url}
                    description={card.fields.description}
                    useCase={card.fields.useCase?.fields.title}
                    verticals={card.fields.verticalId}
                    privacy={card.fields.privacy}
                    createdAt={card.sys.createdAt}
                    link={card.fields.link}
                    linkPartner={card.fields.linkPartners}
                    docPartner={card.fields.docPartners}
                    username={card.fields.username}
                    password={card.fields.password}
                    type={card.fields.typeOfContent}
                    hightlight={card.fields.isHighlight}
                  />
                </Grid>
              ))}
            </Grid>
            <div className="pagination">
              <Typography
                variant="caption"
                component="span"
                className="textPagination"
              >
                {" "}
                {firstElement} - {lastElement} of {data.total}
              </Typography>
              <IconButton
                disabled={isFirstPage}
                aria-label="prev"
                onClick={(e) => prevPage(e)}
              >
                <ArrowBackIcon fontSize="small" />
              </IconButton>
              <IconButton
                disabled={isLastPage}
                aria-label="next"
                onClick={(e) => nextPage(e)}
              >
                <ArrowForwardIcon fontSize="small" />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default GridPost;
