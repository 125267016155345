import * as React from "react";
import {
  createMuiTheme,
  CssBaseline,
  responsiveFontSizes,
  ThemeProvider,
} from '@material-ui/core';
import { cartoThemeOptions } from '@carto/react-ui';
import {
  BrowserRouter as Router, 
  Route,
  Switch,
} from 'react-router-dom';
import { auth } from './firebase/ConfigFirebase';
import { closeSession } from "./firebase/FunctionsFirebase";


import './App.scss';
import Home from "./components/Home";
import Login from "./components/Login"
/* import ForgotPassword from "./components/Forgot-Password"; */

let theme = createMuiTheme(cartoThemeOptions);
theme = responsiveFontSizes(theme, {
  breakpoints: cartoThemeOptions.breakpoints.keys,
  disableAlign: false,
  factor: 2,
  variants: [
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'subtitle1',
    'subtitle2',
    'body1',
    'body2',
    'button',
    'caption',
    'overline',
  ],
});

function App() {
  const [firebaseUser, setFirebaseUser] = React.useState(false);

  React.useEffect(() => {
    const fetchUser = () => {  // Consigue el currentUser
      auth.onAuthStateChanged(user => {
          if(user){
            setFirebaseUser({ // La guarda en un estado
              displayName : user.displayName, 
              email: user.email,
              uid: user.uid,
              emailVerified: user.emailVerified,
              photoURL: user.photoURL,
              provider: user.providerData[0].providerId});
          }else{
              setFirebaseUser(false)
          }
      })
    }
    fetchUser()
  }, [])

  const cartoMail = () => {
    if(firebaseUser){
      const email = firebaseUser.email;
      if (firebaseUser.provider === "google.com")  {
        if (/@carto.com\s*$/.test(email) || /@cartodb.com\s*$/.test(email)) {
          return <Home type={firebaseUser.provider}/>;
        }
        closeSession();
        return  <Login invalid={true} /> ;
      } else if (firebaseUser.provider === "password") {
        return <Home type={firebaseUser.provider}/>
      }
    }
    return <Login invalid={false} />;
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Switch> 
            <Route exact path="/forgot-password">
              {/* <ForgotPassword /> */}
            </Route>
          {cartoMail()}
          </Switch>
        </Router>  
      </ThemeProvider>
    </>
  );
}

export default App;
