import firebase from 'firebase/app';
import 'firebase/auth';

// Llave de firebase para poder acceder a la base de datos que tenemos en ella 
const firebaseConfig = {
    apiKey: "AIzaSyDhBKGtAg3rIT9dm8Wb5uFc4HLEJFD1twQ",
    authDomain: "carto-resource-center.firebaseapp.com",
    projectId: "carto-resource-center",
    storageBucket: "carto-resource-center.appspot.com",
    messagingSenderId: "448291090032",
    appId: "1:448291090032:web:a861d787c3dc3c576c4eb5"
};

firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
export {auth, firebase}