import * as React from "react";
import {
  Paper,
  List,
  ListItem,
  Collapse,
  ListItemText,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

const FilterType = (props) => {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Paper className="filter">
      <List component="nav" aria-label="main mailbox folders">
        <ListItem button onClick={handleClick}>
          {props.icon}
          <ListItemText primary={props.title} style={{ marginLeft: "1rem" }} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem className="checkboxType">
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    value="Customer Story,"
                    onChange={props.action}
                  />
                }
                label="Customer Story"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    value="Demo-Builder,Demo-Verticalized Apps,Demo-Workflows,Demo-Custom App,"
                    onChange={props.action}
                    checked={props.checkDemo}
                  />
                }
                label="Demo"
              />
              <ListItem className="checkboxType">
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      value="Demo-featured,"
                      onChange={props.action}
                      checked={props.checkFeatured}
                    />
                  }
                  label="Featured"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      value="Demo-Builder,"
                      onChange={props.action}
                      checked={props.checkBuilder}
                    />
                  }
                  label="Builder"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      value="Demo-Custom App,"
                      onChange={props.action}
                      checked={props.checkCustom}
                    />
                  }
                  label="Custom App"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      value="Demo-Verticalized Apps,"
                      onChange={props.action}
                      checked={props.checkCarto3}
                    />
                  }
                  label="Verticalized Apps"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      value="Demo-Workflows,"
                      onChange={props.action}
                      checked={props.checkFrames}
                    />
                  }
                  label="Workflows"
                />
              </ListItem>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    value="One Pager,"
                    onChange={props.action}
                  />
                }
                label="One Pager"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    value="Proposal,"
                    onChange={props.action}
                  />
                }
                label="Proposal"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    value="Report / Ebook,"
                    onChange={props.action}
                  />
                }
                label="Report / Ebook"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    value="Webinar,"
                    onChange={props.action}
                  />
                }
                label="Webinar"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    value="Deck,"
                    onChange={props.action}
                  />
                }
                label="Deck"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    value="Video,"
                    onChange={props.action}
                  />
                }
                label="Video"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    value="Sales Enablement,"
                    onChange={props.action}
                  />
                }
                label="Sales Enablement"
              />
            </ListItem>
          </List>
        </Collapse>
      </List>
    </Paper>
  );
};

export default FilterType;
