import React from 'react';
import PropTypes from "prop-types";
import ReactMarkdown from 'react-markdown'
import { Divider, Chip, IconButton, Tooltip, Link } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import PrivateIcon from '@material-ui/icons/LockOutlined';
import PublicIcon from '@material-ui/icons/LockOpenOutlined';
import LinkPrivacyIcon from '@material-ui/icons/CollectionsBookmarkOutlined';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import LinkOutlinedIcon from '@material-ui/icons/LinkOutlined';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import QueryBuilderOutlinedIcon from '@material-ui/icons/QueryBuilderOutlined';
import StarIcon from '@material-ui/icons/Star';


export default function MediaCard({title, userType, imgURL, description, useCase, verticals, privacy, createdAt, link, linkPartner, docPartner, username, password, contentfulId, type, hightlight}) {
  const [linkToUse, setLinkToUse] = React.useState('#');
  const [copyLinkTooltip, setCopyLinkTooltip] = React.useState('Copy Link');
  const [copyUserTooltip, setCopyUserTooltip] = React.useState('Copy Username');
  const [copyPassTooltip, setCopyPassTooltip] = React.useState('Copy Password'); 

  React.useEffect(() => {
    const determineLink = () => {
      if (userType === "password") {
        if (linkPartner) {
          return setLinkToUse(linkPartner);
        } else if (docPartner) {
          const fullLink = "https:".concat(docPartner.fields.file.url);
          return setLinkToUse(fullLink);
        }
        return setLinkToUse(link);
      } else {
        return setLinkToUse(link);
      }
    }
    determineLink();
  }, [docPartner, link, linkPartner, userType]);

  // Button of type of privacy
  const privacyDetail = (privacy) => {
    switch(privacy) {
      case 'public': return (<><PublicIcon fontSize="small" className='greyIcon'/><Typography variant="caption" component="span" color="textSecondary">Public</Typography></>);
      case 'private': return (<><PrivateIcon fontSize="small" className='greyIcon'/><Typography variant="caption" component="span" color="textSecondary">Private</Typography></>);
      case 'link': return (<><LinkPrivacyIcon fontSize="small"  className='greyIcon'/><Typography variant="caption" component="span" color="textSecondary">Link</Typography></>);
      default: return '';
    }
  }

  const convertDate = (inputFormat) => {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    var d = new Date(inputFormat)
    return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/')
  }

  // The User and Password Component of the card
  const userAndPassButtons = (username, password) => {
    const styleUsername = (username ? {color: "primary"} : {disabled: true});
    const stylePassword = (password ? {color: "primary"} : {disabled: true});
    return (
      <>
        <Tooltip title={copyUserTooltip} onClose={() => handleClose('user')} placement='top' arrow >
          <IconButton size='small' aria-label="Copy the username" {...styleUsername} onClick={() => handleClickIconButton(username, 'user')}>
            <PersonOutlinedIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title={copyPassTooltip} onClose={() => handleClose('pass')} placement='top' arrow >
          <IconButton size='small' aria-label="Copy the password" {...stylePassword} onClick={() => handleClickIconButton(password, 'pass')}>
            <VpnKeyOutlinedIcon fontSize="small" />
          </IconButton> 
        </Tooltip>
      </>
    );
  }

  // Text of the tooltip and action when the copied link, pass or username
  const handleClickIconButton = (copyText, icon) => {
    navigator.clipboard.writeText(copyText);
    switch(icon) {
      case 'link': return setCopyLinkTooltip('Link copied!');
      case 'user': return setCopyUserTooltip('Username copied!');
      case 'pass': return setCopyPassTooltip('Password copied!');
      default: return;
    }
  }

  // When the mouse moves and the tooltip is closed
  const handleClose = (tooltip) => {
    switch(tooltip) {
      case 'link': return setCopyLinkTooltip('Copy Link');
      case 'user': return setCopyUserTooltip('Copy Username');
      case 'pass': return setCopyPassTooltip('Copy Password');
      default: return;
    }

  };

  return (
    <Card className='card' variant="outlined" >
      <div>
        {type && <Chip label={type} size="small" color="primary" className='typeChip' />}
        <CardMedia
          className='media'
          image={imgURL}
          title={title}
          loading="lazy"
        />
        <div className="contentCard">
          <Typography variant="overline" color="textSecondary">
            {useCase}
          </Typography>
          <div className='titleDiv'>
          <Link href={linkToUse} target="_blank" rel="noreferrer noopener" underline="none">
            <Typography gutterBottom variant="h5" component="h2" className='title' title={title} color="primary">
            
              {title}
            
            </Typography>
            </Link>
            {hightlight && <StarIcon color='secondary'  className='star'/>}
          </div>
          <Typography variant="body1" component="p" className='description' >
            <ReactMarkdown>{description}</ReactMarkdown>
          </Typography>
        </div>
      </div>
      <div className='containerChips'>
        {verticals && verticals.map((vertical, i) => (
          <Chip key={i} label={vertical.fields.title} variant="outlined" size="small" color="primary" className='chip' />
        ))}
      </div>
      <Divider variant="middle" /> 
      <CardActions className='iconsContainer'>
        <div style={{display: 'flex', margin: '0.2rem 0.5rem' }}>
          <div className='privacyDiv'>
            { privacyDetail(privacy) }
          </div>
          <div className='privacyDiv'>
            <QueryBuilderOutlinedIcon fontSize="small" className='greyIcon'/>
            <Typography variant="caption" component="span" color="textSecondary">Published {convertDate(createdAt)}</Typography>
          </div>
        </div>
        <div style={{display: 'flex', margin: '0.5rem 0.2rem', justifyContent: 'space-between' }}>
          <div className='privacyDiv'>
            <Button startIcon={<ReportProblemOutlinedIcon />} size='small' className='iconBug'>
              <a href={`mailto:support@carto.com?subject=RESOURCE CENTER - Problems with ${title}&body=Hi! I've detected a problem in the following item in the Resource Center >>>%0D%0A%0D%0ATitle: ${title}%0D%0ALink: ${linkToUse}%0D%0AProblem:  %0D%0A%0D%0AThanks!`}>
                Report bug
              </a>
            </Button>
          </div>
          <div>
            <Tooltip title="Open in a new tab" placement='top' arrow >
              <a href={linkToUse} target="_blank" rel="noreferrer noopener">
                <IconButton size='small' aria-label="Open in a new tab" color="primary">
                  <OpenInNewIcon fontSize="small" />
                </IconButton>
              </a>
            </Tooltip>
            <Tooltip title={copyLinkTooltip} onClose={() => handleClose('link')} placement='top' arrow >
              <IconButton size='small' aria-label="Copy the link" color="primary" onClick={() => handleClickIconButton(linkToUse, 'link')}>
                <LinkOutlinedIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            {userAndPassButtons(username, password)}
          </div>
        </div>
      </CardActions>
    </Card>
  );
}

MediaCard.propTypes = {
  title: PropTypes.string,
  imgURL: PropTypes.string,
  description: PropTypes.string,
  useCase: PropTypes.string,
  privacy: PropTypes.string, 
  createdAt: PropTypes.string,
  username: PropTypes.string,
  password: PropTypes.string,
  verticals: PropTypes.array,
  link: PropTypes.string,
  contentfulId: PropTypes.string,
  type: PropTypes.array,
  hightlight: PropTypes.bool
}

MediaCard.defaultProps = {
  title: 'Not Found',
  imgURL: 'https://carto.com/media/placeholder_image_card_ee0752be48/placeholder-image-card.jpeg_placeholder_image_card_ee0752be48.jpeg',
  description: 'Not Found',
  useCase: ' ',
  privacy: false,
  username: '',
  password: '',
  verticals: null,
  link: '#',
  contentfulId: '',
  type: null,
  hightlight: false
}