import React, { useState } from "react";
import PropTypes from "prop-types";
import { Typography, TextField, Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
/* import { Link } from 'react-router-dom'; */
import ButtonGoogle from "../firebase/LoginGoogle";
import { auth } from "../firebase/ConfigFirebase.js";
import CartoMarker from "../img/CARTO-logo-positive.svg";

function Login({ invalid }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorLogin, setErrorLogin] = useState(false);
  const [errorText, setErrorText] = useState("");

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const signInUser = (email, password) => {
    auth
      .signInWithEmailAndPassword(email, password)
      .then(() =>
        localStorage.setItem(
          "user",
          JSON.stringify({
            displayName: auth.currentUser.displayName,
            email: auth.currentUser.email,
            uid: auth.currentUser.uid,
            emailVerified: auth.currentUser.emailVerified,
            photoURL: auth.currentUser.photoURL,
          })
        )
      )
      .catch((error) => {
        // Handle Errors here.
        const errorMessage = error.message;
        setErrorLogin(true);
        setErrorText(errorMessage);
      });
  };

  return (
    <div className="login">
      {invalid && (
        <Alert severity="error">Please log in with a CARTO Account</Alert>
      )}
      <div className="container">
        <div className="imgContainer">
          <img src={CartoMarker} alt="Carto Marker" />
        </div>
        <Typography variant="h4" align="center">
          Resource Center
        </Typography>
        <Typography variant="h6" align="center">
          Log in
        </Typography>
        <TextField
          error={errorLogin}
          label="Email"
          className="input-login"
          onChange={(e) => handleChangeEmail(e)}
        ></TextField>
        <TextField
          error={errorLogin}
          label="Password"
          type="password"
          className="input-login"
          helperText={errorText}
          onChange={(e) => handleChangePassword(e)}
        ></TextField>
        <Button
          color="primary"
          variant="contained"
          onClick={() => signInUser(email, password)}
        >
          Continue
        </Button>
        {/* <Link to="/forgot-password" className="forgot-password">Forgot password?</Link> */}
        <Typography
          variant="body2"
          color="textSecondary"
          className="title-google"
        >
          Please access only with your CARTO email account:
        </Typography>
        <ButtonGoogle />
      </div>
    </div>
  );
}

export default Login;

Login.propTypes = {
  invalid: PropTypes.bool,
};

Login.defaultProps = {
  invalid: false,
};
