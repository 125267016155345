import * as React from "react"
import {
  Typography,
  Grid, 
  makeStyles,
  Button,
  Link
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CallMadeIcon from '@material-ui/icons/CallMade';
import Logo from '../img/logo-carto-1.png';
import { closeSession } from "../firebase/FunctionsFirebase";

const useStyles = makeStyles((theme) => ({
  gridLogo: {
    margin: '0',
    justifyContent: "space-between"
  },
  buttonLog: {
    color: theme.palette.common.white,
    border: '2px solid white',
    marginLeft: '1rem', 
    marginRight: '0.8rem'
  },
  buttonOpp: {
    marginRight: '1rem', 
  },
  textTitle: {
    color: theme.palette.common.white
  }, 
  linkLogo: {
    textDecoration: `none`,
    display: 'flex',
    color: 'white',
    alignItems: 'center'
  }, 
  header: {
    backgroundColor: '#162945',
    marginBottom: `1rem`,
  },
  headerContainer: {
    margin: `0 auto`,
    padding: `0.8rem 0`,
  }
}));

function Header ({type}) { 
  const classes = useStyles();

  return (
    <header className={classes.header}>
      <div className={classes.headerContainer}>
        <Grid container className={classes.gridLogo} spacing={3}   direction="row" alignItems="center">
          <Grid item sm={1}></Grid>
          <Grid item sm={10}>
            <Grid container   direction="row" justifyContent="space-between" alignItems="center">
              <a href="https://carto.com" className={classes.linkLogo} >
                  <img src={Logo} alt="Carto logo" placeholder="blurred" width={100} style={{marginRight: '1rem'}}/>
                  <Typography variant="h6" component="h1" className={classes.textTitle} >
                    Resource Center
                  </Typography>
              </a>
              <div>
                {type === "password" &&  <Link href="https://carto.com/partners/opportunity/" underline="none" target="_blank" >
                  <Button variant='contained' color='primary' className={classes.buttonOpp} startIcon={<CallMadeIcon />} >Register an Opportunity</Button>
                </Link>}
                <Link href="https://forms.gle/Jxq984jrdrQBmkmCA" underline="none" target="_blank" rel="noreferrer noopener" >
                  <Button variant='contained' color='primary' startIcon={<AddIcon />} >Submit Resource</Button>
                </Link>
                  <Button variant='outlined' className={classes.buttonLog} startIcon={<ExitToAppIcon/>} onClick={closeSession}>Log Out</Button>
              </div>
            </Grid>            
          </Grid>
          <Grid item sm={1}></Grid>
        </Grid>
      </div>
    </header>
  )
}

export default Header
