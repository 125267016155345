import React from 'react';
import Header from './header';
import GridPost from './gridPost';

const Home = ({type}) => {
    return (
        <div>
            <Header type={type} siteTitle={'Resource Center'} />
            <main><GridPost type={type} /></main>
        </div>
    )
}

export default Home
