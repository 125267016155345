module.exports = Object.freeze({
  FILTER_INDUSTRY: [
    { value: "1h2xy9PCQdMroNPBiBZ9h8", title: "Architecture & Engineering" },
    { value: "3a6wI3i4a4U4o2QsCIGKW6", title: "Cities & Governments" },
    { value: "7dBVzUfNp2Zw6VJj1n8nA6", title: "Consulting" },
    { value: "3jzkujGF3xvnFTjD70v5Hx", title: "CPG" },
    { value: "3PXMW10FBYe8SA4aGC2O8k", title: "Education" },
    { value: "5Xrf8VsuxGoiGYiSg6Mwgm", title: "Environmental" },
    { value: "6m8DfsiQhy2M6oQwmqiQqO", title: "Financial Services" },
    { value: "5BKvPFsmD6eaessuC8aEiA", title: "Healthcare & Pharma" },
    { value: "57zF2v8TSMA4aI60Oaa4Q8", title: "Journalism & Media" },
    { value: "sVzQE4E3XHYAlxX29dCKh", title: "Marketing" },
    { value: "2ufyfIU7eUI0AoAswEOoim", title: "Real Estate" },
    { value: "6ug7CpKPYWmK8qmcOs0kMq", title: "Retail" },
    { value: "6xHHRRdgcHLrmPXYFiCawt", title: "Software & Tech" },
    { value: "5x8Xnq6J8oeQvl2Zvc8nz", title: "Sports & Entertainment" },
    { value: "6WYGV9mMxkSNVuDtE21GcR", title: "Telecommunications" },
    { value: "1fitQL7fSKGWACACyqMQsu", title: "Transport & Logistics" },
    { value: "35ltoyffIsmaEIEIMykCWi", title: "Utilities" },
    { value: "0q2mssuycNUwX0b3Xwkfm", title: "Insurance" },
  ],
  FILTER_USECASE: [
    { value: "1UNpPklubcU3Ru6A90oKt9", title: "Catastrophe Analytics" },
    { value: "CY7Y6wZraHCsE2SQeUP2i", title: "Data Monetization" },
    { value: "3WSOUjvYnJUzG2OFvW7esC", title: "Data Observatory" },
    { value: "129RiTaJmt2HWUD8Ek9LgL", title: "Data Science" },
    /*       { value: "6J4BfgF3sBjaAIV7r4uchm", title: "E-Commerce" }, */
    {
      value: "6yrvm63x0egXLUwyyio73f",
      title: "Environmental Analysis & Sustainability",
    },
    { value: "3TwcSGxXp9LrYR71857scm", title: "Fraud Detection" },
    { value: "1F8g3X7jbyyy6GuYqI8iwc", title: "Geomarketing" },
    {
      value: "YxL0aUjUilLYs1879sdNu",
      title: "Healthcare & Social Factor Analysis",
    },
    { value: "5WDswCdiQEpFBqVHrXNGIU", title: "Indoor Mapping" },
    { value: "qylzPWebPE82oo8MiwCKE", title: "Investment Analysis" },
    {
      value: "2YMpoEmIZGqqO42SEyQmIy",
      title: "Logistics & Supply Chain Management",
    },
    { value: "2XswKgOaSIMCeSWUuWIGga", title: "Mobility Planning" },
    {
      value: "2CG4WUInO74Zwk2VY2Z9vv",
      title: "Network Deployment & Optimization",
    },

    { value: "3jc7dqi8ly8gKUoaKoqEeo", title: "Risk Analysis" },
    { value: "1FsfgM1mmJH76RVU7I2YUu", title: "Route Optimization" },
    { value: "3uLlxHfqsMOIq8qSY2ECCW", title: "Site Planning" },
    { value: "5PNRovFvL0e4TXV4AQQ72N", title: "Smart Cities & IOT" },
    { value: "3AL4zNozXaAsUmG8ic8GcK", title: "Territory Management" },
    { value: "6R7xfUyUk8wkIGsO2SSAIS", title: "Other" },
  ],
  FILTER_PRIVACY: [
    { value: "public", title: "Public" },
    { value: "private", title: "Private" },
    { value: "link", title: "Link" },
  ],
  FILTER_TYPE2: [
    { value: "Customer Story", title: "Customer Story", lvl: 1 },
    {
      value:
        "Demo-Builder,Demo-Verticalized Apps,Demo-Workflows,Demo-Custom App,",
      title: "Demo",
      lvl: 1,
    },
    { value: "Demo-Builder", title: "Builder", lvl: 2 },
    { value: "Demo-featured", title: "Featured", lvl: 2 },
    { value: "Demo-Verticalized Apps", title: "CARTO 3", lvl: 2 },
    { value: "Demo-Workflows", title: "CARTOframes", lvl: 2 },
    { value: "Demo-Custom App", title: "Custom", lvl: 2 },
    { value: "One Pager", title: "One Pager", lvl: 1 },
    { value: "Proposal", title: "Proposal", lvl: 1 },
    { value: "Report / Ebook", title: "Report / Ebook", lvl: 1 },
    { value: "Webinar", title: "Webinar", lvl: 1 },
    { value: "Video", title: "Video", lvl: 1 },
    { value: "Deck", title: "Deck", lvl: 1 },
    { value: "Sales Enablement", title: "Sales Enablement", lvl: 1 },
  ],
  FILTER_LOCATION: [
    { title: "Global", value: "Global" },
    { title: "Africa", value: "Africa" },
    { title: "Asia", value: "Asia" },
    { title: "Argentina", value: "Argentina" },
    { title: "Australia", value: "Australia" },
    { title: "Brazil", value: "Brazil" },
    { title: "Canada", value: "Canada" },
    { title: "Colombia", value: "Colombia" },
    { title: "England", value: "England" },
    { title: "Europe", value: "Europe" },
    { title: "France", value: "France" },
    { title: "Germany", value: "Germany" },
    { title: "India", value: "India" },
    { title: "Italy", value: "Italy" },
    { title: "Japan", value: "Japan" },
    { title: "Lebanon", value: "Lebanon" },
    { title: "Malaysia", value: "Malaysia" },
    { title: "Mexico", value: "Mexico" },
    { title: "Netherlands", value: "Netherlands" },
    { title: "Peru", value: "Peru" },
    { title: "Portugal", value: "Portugal" },
    { title: "Romania", value: "Romania" },
    { title: "Saudi Arabia", value: "Saudi Arabia" },
    { title: "Spain", value: "Spain" },
    { title: "South Africa", value: "South Africa" },
    { title: "Switzerland", value: "Switzerland" },
    { title: "Thailand", value: "Thailand" },
    { title: "UK", value: "UK" },
    { title: "US", value: "US" },
  ],
  FILTER_TYPE: [
    { value: "Customer Story", title: "Customer Story" },
    { value: "Demo-Builder", title: "Builder" },
    { value: "Demo-Verticalized Apps", title: "CARTO 3" },
    { value: "Demo-Workflows", title: "CARTOframes" },
    { value: "Demo-Custom App", title: "Custom" },
    { value: "One Pager", title: "One Pager" },
    { value: "Proposal", title: "Proposal" },
    { value: "Report / Ebook", title: "Report / Ebook" },
    { value: "Webinar", title: "Webinar" },
  ],
  FILTER_PRODUCT: [
    { value: "Demo-Builder", title: "Builder" },
    { value: "Demo-Verticalized Apps", title: "CARTO 3" },
    { value: "Demo-Workflows", title: "CARTOframes" },
    { value: "Demo-Custom App", title: "Custom" },
  ],
  FILTER_CLOUD: [
    { value: "7AiBS1wyyPuqFSUoRHBtOt", title: "Google BigQuery" },
    { value: "2IktW1pcHxauibqFmGAWrJ", title: "Snowflake" },
    { value: "7zrkzchc1UBOALlMKhrv6j", title: "AWS Redshift" },
    { value: "RJ9a4ddRXWn3dcgsWrS9q", title: "Databricks" },
  ],
});
