import { auth } from "./ConfigFirebase";

// Función que cierra sesión
const closeSession = () => {
  auth
    .signOut()
    .then(() => {
      localStorage.removeItem("user");
    })
    .catch((error) => {
      console.log(error);
    });
};

export { closeSession };
