import * as React from "react";
import {
  Paper,
  List,
  ListItem,
  Collapse,
  ListItemText,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';


const Filter = (props) => {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Paper className='filter'>
        <List component='nav' aria-label='main mailbox folders'>
        <ListItem button onClick={handleClick}>
            {props.icon}
            <ListItemText primary={props.title} style={{marginLeft: '1rem'}}/>
            {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
            {
              props.subItem.map( (item, i) => (
                <ListItem key={`${item.value}-${i}`}>
                  <FormControlLabel control={<Checkbox color='primary' value={item.value} onChange={props.action}/>} label={item.title}/>
                </ListItem>
              ))
            }
            </List>
        </Collapse>
        </List>
    </Paper>
  )
}

export default Filter